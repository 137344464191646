<template>
  <main>
    <h2>Website Terms and Conditions of Use</h2>
<br>
    <h3>1. Terms</h3>

    <p>
      By accessing this Website, accessible from https://www.matriclive.com, you
      are agreeing to be bound by these Website Terms and Conditions of Use and
      agree that you are responsible for the agreement with any applicable local
      laws. If you disagree with any of these terms, you are prohibited from
      accessing this site. The materials contained in this Website are protected
      by copyright and trade mark law.
    </p>

    <h3>2. Use License</h3>

    <p>
      Permission is granted to temporarily download one copy of the materials on
      SAADC's Website for personal, non-commercial transitory viewing only. This
      is the grant of a license, not a transfer of title, and under this license
      you may not:
    </p>

    <ul>
      <li>modify or copy the materials;</li>
      <li>
        use the materials for any commercial purpose or for any public display;
      </li>
      <li>
        attempt to reverse engineer any software contained on SAADC's Website;
      </li>
      <li>
        remove any copyright or other proprietary notations from the materials;
        or
      </li>
      <li>
        transferring the materials to another person or "mirror" the materials
        on any other server.
      </li>
    </ul>

    <p>
      This will let SAADC to terminate upon violations of any of these
      restrictions. Upon termination, your viewing right will also be terminated
      and you should destroy any downloaded materials in your possession whether
      it is printed or electronic format. These Terms of Service has been
      created with the help of the
      <a href="https://www.termsofservicegenerator.net">Terms Of Service Generator</a>
      and the
      <a href="https://www.generateprivacypolicy.com">Privacy Policy Generator</a>.
    </p>

    <h3>3. Disclaimer</h3>

    <p>
      All the materials on SAADC's Website are provided "as is". SAADC makes no
      warranties, may it be expressed or implied, therefore negates all other
      warranties. Furthermore, SAADC does not make any representations
      concerning the accuracy or reliability of the use of the materials on its
      Website or otherwise relating to such materials or any sites linked to
      this Website.
    </p>

    <h3>4. Limitations</h3>

    <p>
      SAADC or its suppliers will not be hold accountable for any damages that
      will arise with the use or inability to use the materials on SAADC's
      Website, even if SAADC or an authorize representative of this Website has
      been notified, orally or written, of the possibility of such damage. Some
      jurisdiction does not allow limitations on implied warranties or
      limitations of liability for incidental damages, these limitations may not
      apply to you.
    </p>

    <h3>5. Revisions and Errata</h3>

    <p>
      The materials appearing on SAADC's Website may include technical,
      typographical, or photographic errors. SAADC will not promise that any of
      the materials in this Website are accurate, complete, or current. SAADC
      may change the materials contained on its Website at any time without
      notice. SAADC does not make any commitment to update the materials.
    </p>

    <h3>6. Links</h3>

    <p>
      SAADC has not reviewed all of the sites linked to its Website and is not
      responsible for the contents of any such linked site. The presence of any
      link does not imply endorsement by SAADC of the site. The use of any
      linked website is at the user's own risk.
    </p>

    <h3>7. Site Terms of Use Modifications</h3>

    <p>
      SAADC may revise these Terms of Use for its Website at any time without
      prior notice. By using this Website, you are agreeing to be bound by the
      current version of these Terms and Conditions of Use.
    </p>

    <h3>8. Your Privacy</h3>

    <p>Please read our Privacy Policy.</p>

    <h3>9. Governing Law</h3>

    <p>
      Any claim related to SAADC's Website shall be governed by the laws of za
      without regards to its conflict of law provisions.
    </p>
    <br>
    <br>
    <h2>Subscription Terms and Conditions for Matric Live AI Features</h2>
    <br>

    <h3>1. Introduction</h3>

    <p>
      These Subscription Terms and Conditions ('Terms') govern your use of the AI features on the Matric Live mobile
      application ('App') provided by SAADC (Pty) Ltd ('we,' 'us,' or 'our'). By subscribing to these features, you
      agree to be bound by these Terms.
    </p>

    <h3>2. Subscription Plans</h3>

    <p>We offer the following subscription plans:</p>
    <ul>
      <li>Weekly Subscription: R 19.99 per week</li>
      <li>Monthly Subscription: R 69.99 per month</li>
      <li>Yearly Subscription: R 799.99 per year</li>
    </ul>

    <h3>3. Billing and Payments</h3>

    <p>
      a. Payment Method: You must provide a valid payment method (such as a credit card) to subscribe to the AI
      features. Your payment method will be charged automatically according to the subscription plan you select.
    </p>
    <p>
      b. Recurring Billing: By purchasing a subscription, you authorize us to charge your payment method on a recurring
      basis (weekly, monthly, or yearly) based on your chosen plan.
    </p>
    <p>
      c. Billing Cycle: Your subscription will commence on the date of purchase and renew automatically at the end of
      each billing cycle unless canceled.
    </p>

    <h3>4. Cancellation and Refunds</h3>

    <p>
      a. Cancellation: You may cancel your subscription at any time through your account settings in the App.
      Cancellation will take effect at the end of the current billing period.
    </p>
    <p>
      b. Refunds: We do not provide refunds for any partial subscription periods or unused services.
    </p>

    <h3>5. Changes to Subscription Plans</h3>

    <p>
      We reserve the right to modify the subscription fees or introduce new fees at any time. We will provide you with
      reasonable notice of any changes to the fees or subscription plans.
    </p>

    <h3>6. License and Access</h3>

    <p>
      a. License: Upon successful subscription, you will be granted a limited, non-transferable, non-exclusive license
      to access and use the AI features on the App.
    </p>
    <p>
      b. Restrictions: You may not sublicense, transfer, or otherwise assign your rights to use the AI features.
      Unauthorized use or access of the AI features is strictly prohibited.
    </p>

    <h3>7. Termination</h3>

    <p>
      We reserve the right to terminate or suspend your subscription and access to the AI features at any time, without
      prior notice, if you breach these Terms or engage in any fraudulent or illegal activity.
    </p>

    <h3>8. Limitation of Liability</h3>

    <p>
      To the maximum extent permitted by law, SAADC (Pty) Ltd shall not be liable for any direct, indirect, incidental,
      special, consequential, or punitive damages arising out of or related to your use of the AI features.
    </p>

    <h3>9. Amendments</h3>

    <p>
      We may amend these Terms from time to time. We will notify you of any material changes by posting the updated
      Terms on our website or through the App. Your continued use of the AI features after the effective date of the
      updated Terms constitutes your acceptance of the changes.
    </p>

    <h3>10. Governing Law</h3>

    <p>
      These Terms shall be governed by and construed in accordance with the laws of the Republic of South Africa.
    </p>

    <h3>11. Contact Information</h3>

    <p>
      For any questions or concerns regarding these Terms, please contact us at:
    </p>
    <p>
      SAADC (Pty) Ltd<br>
      2nd Floor, Brickfield Canvas, 35 Brickfield Rd, Woodstock, Cape Town, 7925<br>
      <a href="mailto:Info@saadc.co.za">Info@saadc.co.za</a><br>
      <a href="tel:+13022083031">+13022083031</a>
    </p>

    <h3>12. Acknowledgment</h3>

    <p>
      By subscribing to the AI features on the Matric Live App, you acknowledge that you have read, understood, and
      agree to be bound by these Terms.
    </p>
  </main>
</template>

<script>
export default {
  name: "TermsOfService",
};
</script>

<style scoped>
main {
  background-image: url("../../assets/careerspage/Desktop - 1.svg");
  background-size: auto;
  text-align: start;
  padding: 8%;
}
main h2 {
  font-weight: 600;
}
main h1 {
  font-weight: 600;
}
main h3 {
  font-weight: 600;
}
</style>